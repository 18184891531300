import { useJuspay } from './useJuspay'
import { usePayrails } from './usePayrails'
import { unref } from 'vue'

export function usePaymentIntegration() {
  const { processJuspayPayment, loadJuspayScript } = useJuspay()
  const { processPayrailsPayment } = usePayrails()

  const initializePaymentProvider = async (provider: string) => {
    if (provider === 'juspay') {
      await loadJuspayScript()
    }
  }

  const initPayment = async (paymentData: InitiatePaymentResponseJuspay | InitiatePaymentResponsePayrails) => {
    if (!paymentData || paymentData.status !== 'success') return

    if (paymentData.paymentProvider === 'juspay') {
      try {
        await processJuspayPayment(unref(paymentData))
      } catch (error) {
        console.error('Failed to process Juspay payment:', error)
      }
    } else if (paymentData.paymentProvider === 'payrails') {
      try {
        await processPayrailsPayment(paymentData)
      } catch (error) {
        console.error('Failed to process Payrails payment:', error)
      }
    }
  }

  return {
    initPayment,
    initializePaymentProvider
  }
}
